
// renders a loading spinner in the center of a given container element
export function renderLoadingSpinner(container, id = "loading-spinner") {
  const loadingSpinner = document.createElement("div");
  loadingSpinner.innerHTML = '<div style="border-top-color:transparent" class="spinner"></div>';
  loadingSpinner.classList.add("flex", "justify-center", "items-center", "w-full", "py-6");
  loadingSpinner.id = id;
  container.appendChild(loadingSpinner);
};

export function removeLoadingSpinner(id = "loading-spinner") {
  const loadingSpinner = document.getElementById(id);
  loadingSpinner.remove();
};
