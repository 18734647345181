import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'
import { renderLoadingSpinner, removeLoadingSpinner } from "../loading_spinner";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="document-inference"
export default class extends Controller {
  static values = {
    objectName: String,
  }

  static targets = ["file", "errorActions"]

  loadingSpinnerID = `${this.objectNameValue}inference_results_loading_spinner`;

  async generate(event) {
    event.preventDefault();
    const form = event.target.closest('form');
    const formData = new FormData(form);
    const file = formData.get(this.fileTarget.name);
    const base64 = await this.toBase64(file);
    this.renderLoading();

    const res = await post(`/dashboard/waste_document_inference`, {
      contentType: "application/json",
      responseKind: "turbo_stream",
      body: JSON.stringify({
        waste_document_inference: {
          file: {
            data: base64.replace("data:application/pdf;base64,", ""),
            mime_type: file.type,
          },
          // object_name is required to allow rendering inference result fields
          // with the correct form object name
          object_name: this.objectNameValue
        }
      }),
    })

    if (!res.ok) {
      this.errorActionsTarget.classList.remove("hidden");
    } else {
      this.errorActionsTarget.classList.add("hidden");
    }

    this.removeLoading();
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    })
  }

  renderLoading() {
    const container = document.getElementById(`${this.objectNameValue}inference_results`);
    renderLoadingSpinner(container, this.loadingSpinnerID);
  }

  removeLoading() {
    removeLoadingSpinner(this.loadingSpinnerID);
  }

  reviewWithoutInference() {
    const url = new URL(window.location);
    url.searchParams.set("inference", "false");
    Turbo.visit(url);
  }
}
